@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;1,9..40,300;1,9..40,400;1,9..40,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300;9..144,400;9..144,500;9..144,600;9..144,700&family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary: rgb(3, 114, 255); /* Fallback color */
}

@layer base {
  h1, h2, h3, h4, h5, h6 {
    @apply font-header;
  }
}

/*html[data-theme="blue"] {*/
/*  --primary: rgb(3, 114, 255);*/
/*}*/

/*html[data-theme="prevost"] {*/
/*  --primary: rgb(191, 142, 68);*/
/*}*/

/*html[data-theme="purple"] {*/
/*  --primary: rgb(108, 58, 214);*/
/*}*/

/*html[data-theme="teal"] {*/
/*  --primary: rgb(6,120,155);*/
/*}*/

/*html[data-theme="forest"] {*/
/*  --primary: rgb(39,107,33);*/
/*}*/

/*html[data-theme="navy"] {*/
/*  --primary: rgb(35,85,131);*/
/*}*/

/*html[data-theme="orange"] {*/
/*  --primary: rgb(193,74,23);*/
/*}*/

/* Grid */
/*html {*/
/*  --tw-bg-opacity: 1; !* Set to 1 for 100% opacity *!*/
/*  font-size: 62.5%; !* Set 1rem to 10px *!*/
/*  --background: theme(colors.background);*/
/*  --scrollbar: 0px;*/
/*  --green: #0b7b2a;*/
/*  --red: #d20d2e;*/
/*  --faded: #636c7a;*/
/*  --totalCols: 10;*/
/*  --scrollbar: 0px;*/
/*  --gridWidth: calc((100vw - var(--scrollbar)) - (var(--margin) * 2));*/
/*  @apply bg-background;*/
/*  --screen: 100svh;*/
/*  --gutter: 1.25rem;*/
/*  --margin: 1.75rem;*/
/*  --col: calc(*/
/*    (*/
/*      (var(--gridWidth) - ((var(--totalCols) - 1) * var(--gutter))) /*/
/*        var(--totalCols)*/
/*    )*/
/*  );*/
/*}*/

.clip {
  clip-path: ellipse(50% 100% at 50% 100%);
  overflow: hidden;
}


/*.grid-container {*/
/*  max-width: 1680px;*/
/*  @apply xxl:mx-auto;*/
/*  @apply margin-px-1;*/
/*}*/

/* Text */

body {
  font-family: 'DM Sans', sans-serif;
}

/*body {*/
/*  !*font-family: 'DM Sans', sans-serif;*!*/
/*  !*lwilliam*!*/
/*  font-family: 'Poppins', sans-serif;*/
/*  !*ernst financial*!*/
/*  !*font-family: 'Lato', sans-serif;*!*/
/*  !*money life coach*!*/
/*  !*font-family: 'Didact Gothic', sans-serif;*!*/
/*  !*should only use font-light, font-normal, font-medium*!*/
/*  @apply md:text-12;*/
/*}*/
/*h1 {*/
/*  !*money life coach*!*/
/*  !*font-family: 'Noto Serif', serif;*!*/
/*  !*ernst financial*!*/
/*  !*font-family: 'Fraunces', serif;*!*/
/*  @apply text-28;*/
/*  @apply sm:text-42;*/
/*  @apply md:text-68;*/
/*  !*@apply md:text-68;*!*/
/*  @apply font-normal;*/
/*}*/

/*h2 {*/
/*  @apply text-19;*/
/*  @apply sm:text-42;*/
/*  @apply font-light;*/
/*}*/

/*h3 {*/
/*  !*@apply sm:text-42;*!*/
/*  @apply text-16;*/
/*  @apply sm:text-28;*/
/*  @apply font-light;*/
/*}*/

/*h4 {*/
/*  @apply text-14;*/
/*  @apply sm:text-19;*/
/*  @apply font-light;*/
/*}*/

/*h5 {*/
/*  @apply text-12;*/
/*  @apply sm:text-16;*/
/*  @apply font-light;*/
/*}*/

/*h6, td {*/
/*  @apply text-12;*/
/*  @apply sm:text-14;*/
/*  @apply font-light;*/
/*}*/

/* tablet */
/*@media screen and (min-width: 768px) {*/
/*  html {*/
/*    --totalCols: 10;*/
/*    --scrollbar: 0px;*/
/*    --screen: 100svh;*/
/*    --gutter: 1.5rem;*/
/*    --margin: 5rem;*/
/*    --col: calc(*/
/*      (*/
/*        (var(--gridWidth) - ((var(--totalCols) - 1) * var(--gutter))) /*/
/*          var(--totalCols)*/
/*      )*/
/*    );*/
/*  }*/
/*}*/
/*!* desktop *!*/
/*@media screen and (min-width: 1440px) {*/
/*  html {*/
/*    --totalCols: 18;*/
/*    --scrollbar: 0px;*/
/*    --screen: 100svh;*/
/*    --gutter: 2.5rem;*/
/*    --margin: 6rem;*/
/*    --col: calc(*/
/*      (*/
/*        (var(--gridWidth) - ((var(--totalCols) - 1) * var(--gutter))) /*/
/*          var(--totalCols)*/
/*      )*/
/*    );*/
/*  }*/
/*}*/

/*!* Max scaling limits total grid width used for calculations *!*/
/*@media screen and (min-width: 1680px) {*/
/*  html {*/
/*    --gridWidth: calc((1680px - var(--scrollbar)) - (var(--margin) * 2));*/
/*  }*/
/*}*/

/*.cdk-overlay-pane {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  pointer-events: auto;*/
/*}*/

/*.cdk-global-overlay-wrapper {*/
/*  position: fixed; !* Add this *!*/
/*  top: 0; !* Add this *!*/
/*  bottom: 0; !* Add this *!*/
/*  left: 0; !* Add this *!*/
/*  right: 0; !* Add this *!*/
/*  pointer-events: none;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background-color: rgba(0, 0, 0, 0.40); !* Adjust the rgba values to your liking *!*/
/*}*/

/*!*svg {*!*/
/*!*  fill: currentColor;*!*/
/*!*}*!*/


/*.my-modal-class {*/
/*  background: white;*/
/*  padding: 3em;*/
/*  border-radius: 8px;*/
/*  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.12);*/
/*  max-width: 60%;*/
/*  pointer-events: auto;*/
/*  z-index: 10000;*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*  max-width: 90%;*/
/*  width: 90%;*/
/*}*/

/*.modal-open {*/
/*  overflow: hidden;*/
/*}*/

.tooltip-icon {
  height: 27px!important;
  width: 27px!important;
}

/*@keyframes slideUp {*/
/*  0% {*/
/*    transform: translateY(100%);*/
/*  }*/
/*  100% {*/
/*    transform: translateY(0);*/
/*  }*/
/*}*/

/*.my-modal-class.slide-up {*/
/*  animation: slideUp 0.3s ease-in-out;*/
/*}*/


.tooltip-container {
  text-align: center;
  z-index: 100;
  position: fixed;
  left: 0;
  padding: 6px 12px;
  font-size: 0.66rem;
  font-weight: 600;
  line-height: initial;
  color: white;
  width: auto;
  background: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -30%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/*.tandem-dropdown {*/
/*  background-color: rgb(248, 248, 247);*/
/*  background-color: rgb(227, 227, 226);*/
/*}*/

/*th {*/
/*  font-weight: normal;*/
/*}*/
